import { Field, Form, Formik } from 'formik';
import { useRouter } from 'next/router';

import Button from 'lib/components/Button';
import InfoText from 'lib/components/InfoText';
import TextField from 'lib/components/forms/TextField';
import login from 'store/auth/login';
import mapErrorsToForm from 'store/mapErrorsToForm';

import FormWrapper from '../FormWrapper';
import Header from '../Header';
import Link from '../Link';

type SignInFormProos = {
  flavorText?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
};

function SigninForm(props: SignInFormProos) {
  const {
    flavorText = (
      <>
        mmm… let’s cook <br />
        Something tasty.
      </>
    ),
    title = 'Vendor Login.',
    subtitle = (
      <>
        <span className="text-center text-neutral-400">
          {' '}
          {"Don't"} have an account? <Link href="/signup">Sign up here</Link>
        </span>
      </>
    ),
  } = props;
  const router = useRouter();

  return (
    <FormWrapper>
      {/*<h1 className="text-3xl font-bold text-black lg:hidden">{flavorText}</h1>*/}
      <Formik
        initialStatus={[]}
        initialValues={{
          identity: '',
          password: '',
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, form) => {
          form.setStatus([]);

          try {
            const { errors, success } = await login(values);

            if (errors) {
              const mappedErrors = mapErrorsToForm(errors);

              if (mappedErrors.nonFieldErrors) {
                form.setStatus(mappedErrors.nonFieldErrors);
              }

              return;
            }

            if (success && (router.pathname === '/signin' || router.pathname === '/signout')) {
              router.push('/');
            }
          } catch (e) {
            form.setStatus(['Something went wrong. Please try again']);
          }
        }}
      >
        {form => (
          <Form>
            <div className="m-auto max-w-[400px] space-y-4">
              <div className="space-y-2">
                <Header>{title}</Header>
                {subtitle && <p className="text-center font-normal text-neutral-400">{subtitle}</p>}
              </div>
              <div className="m-auto max-w-[400px] space-y-0.5 pb-4">
                <Field
                  name="identity"
                  component={TextField}
                  labelStyle="square"
                  label="Username or Email"
                />
                <Field
                  name="password"
                  type="password"
                  component={TextField}
                  labelStyle="square"
                  label="Password"
                />
                {form.status?.map?.((status: string, idx: number) => (
                  <InfoText variant="error" key={idx}>
                    {status}
                  </InfoText>
                ))}
              </div>

              <div className="m-auto flex max-w-[400px] flex-col">
                <Button
                  size="lg"
                  variant="fancy"
                  loading={form.isSubmitting}
                  type={'submit'}
                  disabled={Object.values(form.values).some(v => !v)}
                >
                  Sign In
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <p className="text-center text-sm text-black text-neutral-400">
        Forgot username or password? <Link href="/forgot-password">Ciick here</Link>.
      </p>
    </FormWrapper>
  );
}
export default SigninForm;
